import { CustomDealCustomer } from '..'
import { useAddCustomDealNote } from '../../../hooks/useAddCustomDealNote'
import { useUpdateCustomDealNote } from '../../../hooks/useUpdateCustomDealNote'
import { CustomDealEmployeeAutocomplete } from '../CustomDealEmployeeAutocomplete'
import { CustomDealStatus } from '../CustomDealStatus'
import { ApolloQueryResult } from '@apollo/client'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined'
import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import { FormikErrors } from 'formik'
import parse from 'html-react-parser'
import { pick } from 'lodash'
import get from 'lodash/get'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel'
import { SelectButton } from 'primereact/selectbutton'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CreateNote from '@/components/Note/CreateNote'
import { useGetResponseTemplates } from '@/domains/responseTemplates/hooks'
import { useMutationShowingErrors } from '@/hooks'
import NoteContainer from '@/redesign/components/Note/NoteContainer'
import {
  Company,
  CustomDeal,
  CustomDealCreateArgs,
  CustomDealEmployeeResponse,
  CustomDealEmployeeResponseArgs,
  CustomDealUpdateArgs,
  Customer,
  ECustomDealStatusType,
  EDealType,
  ELanguageCode,
  ETransportMode,
  Employee,
  Note,
  NoteCreateArgs,
  NoteUpdateArgs,
  Query,
  QueryGetCustomDealByIdArgs,
  ResponseTemplate,
} from '@/schemaTypes'
import { displayLocalAmount } from '@/utils/misc'
import styles from './styles.module.scss'

interface Props {
  onEventChange: (status: ECustomDealStatusType) => void
  customDeal: Omit<Partial<CustomDeal>, 'notes'> & {
    notes?: Array<Omit<Note, '_id'> & { _id?: string }> | null
  }
  onChangeField: (
    field: string,
    value:
      | string
      | number
      | null
      | CustomDealEmployeeResponseArgs
      | boolean
      | NoteCreateArgs[],
  ) => void
  errors?: FormikErrors<CustomDealCreateArgs | CustomDealUpdateArgs>
  employees?: Employee[]
  customer?: Customer
  isCarPawn?: boolean
  companies: Company[]
  totalPayoutAmount: number
  minimumPawnDuration: number
  setFieldError: (field: string, value: string | undefined) => void
  refetchCustomDeal?: (
    variables?: Partial<TVariables> | undefined,
  ) => Promise<ApolloQueryResult<TData>>
}

export function CustomDealOverview(props: Props) {
  const {
    customDeal,
    customer,
    onEventChange,
    onChangeField,
    errors,
    employees,
    isCarPawn,
    totalPayoutAmount,
    minimumPawnDuration,
    companies,
    refetchCustomDeal,
  } = props
  const { t } = useTranslation()
  const [selectedResponseTemplate, setSelectedResponseTemplate] =
    useState<ResponseTemplate>()

  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false)

  const isShowNoteToCustomer = [
    ECustomDealStatusType.Declined,
    ECustomDealStatusType.Expired,
  ].includes(customDeal.status!)

  const errorsOnUpdate = errors as FormikErrors<CustomDealUpdateArgs>

  const { responseTemplates } = useGetResponseTemplates()

  const onSelectResponseTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const responseTemplate = responseTemplates.find(
      (template) => template._id === value,
    )
    setSelectedResponseTemplate(responseTemplate)
    onChangeField(
      'employeeResponse',
      pick(responseTemplate, [
        'name',
        'subject',
        'closingText',
        'editableText',
        'openingText',
      ]),
    )
  }

  const onChangeEventStatus = (status: ECustomDealStatusType) => {
    setIsUpdatingStatus(true)
    onEventChange(status)
  }

  const onChangeDealType = (dealType: EDealType) => {
    onChangeField('dealType', dealType)
    onChangeField(
      'durationInDays',
      dealType === EDealType.Pawn ? minimumPawnDuration : 0,
    )
  }

  const addDealNote = useMutationShowingErrors({
    mutation: useAddCustomDealNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const updateCustomDealNote = useMutationShowingErrors({
    mutation: useUpdateCustomDealNote(),
    successMessage: `${t('update_successful')}!`,
  })

  const onAddDealNote = (noteCreateArgs: NoteCreateArgs) => {
    addDealNote({
      variables: { customDealId: customDeal._id, noteCreateArgs },
      onCompleted: () => refetchCustomDeal && refetchCustomDeal(),
    })
  }

  const onUpdateDealNote = (
    noteId: string | number,
    noteUpdateArgs: NoteUpdateArgs,
  ) => {
    updateCustomDealNote({
      variables: { customDealId: customDeal._id, noteId, noteUpdateArgs },
      onCompleted: () => refetchCustomDeal && refetchCustomDeal(),
    })
  }

  const selectedEmployee = employees?.find(
    (employee) => employee._id == customDeal.employeeId,
  )
  const selectedApprover = employees?.find(
    (employee) => employee._id == customDeal.payoutApproverId,
  )

  const dealFormItems: FormItem[] = [
    {
      translationLabel: 'created_at',
      value: (
        <InputText
          disabled={true}
          className="w-64"
          value={dayjs(customDeal.createdAt).format('DD.MM.YYYY HH:mm')}
        />
      ),
      isHidden: !customDeal.createdAt,
    },
    {
      translationLabel: 'company.singular',
      value: (
        <Dropdown
          className="w-64"
          value={customDeal?.companyId ?? ''}
          disabled={
            customDeal.status &&
            ![
              ECustomDealStatusType.Created,
              ECustomDealStatusType.InProgress,
              ECustomDealStatusType.Reviewing,
            ].includes(customDeal.status)
          }
          onChange={(event) => onChangeField('companyId', event.target.value)}
          options={companies
            .filter((company) => !company.successionCompanyId)
            .map((company) => ({ label: company.name, value: company._id }))}
        />
      ),
    },
    {
      translationLabel: 'note',
      value: (
        <div className="w-full max-w-screen-sm">
          <FormControl className="u-mb-sm">
            <InputLabel>{t('template_response')}</InputLabel>
            <Select
              value={selectedResponseTemplate?._id}
              onChange={onSelectResponseTemplate}
            >
              <MenuItem value="" disabled>
                {t('template_response')}
              </MenuItem>
              {responseTemplates.map((template) => (
                <MenuItem key={template._id} value={template._id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
            {errorsOnUpdate?.employeeResponse &&
              typeof errorsOnUpdate.employeeResponse === 'string' && (
                <Typography color="error">
                  {t(errorsOnUpdate.employeeResponse)}
                </Typography>
              )}
          </FormControl>
          {customDeal?.employeeResponse && (
            <>
              <Typography variant="subtitle1">
                {' '}
                {parse(
                  DOMPurify.sanitize(customDeal.employeeResponse.openingText),
                )}
              </Typography>
              <TextField
                rows={5}
                multiline={true}
                fullWidth={true}
                variant="outlined"
                value={customDeal.employeeResponse.editableText}
                onChange={(e) => {
                  onChangeField('employeeResponse', {
                    ...(customDeal.employeeResponse as CustomDealEmployeeResponse),
                    editableText: e.target.value,
                  })
                }}
                className="u-mb-xs"
                style={{ paddingTop: 5, paddingBottom: 5 }}
              />
              {typeof errorsOnUpdate.employeeResponse === 'object' &&
                get(errorsOnUpdate, 'employeeResponse.editableText') && (
                  <Typography color="error">
                    {t(get(errorsOnUpdate, 'employeeResponse.editableText'))}
                  </Typography>
                )}
              <Typography variant="subtitle1">
                {' '}
                {parse(
                  DOMPurify.sanitize(customDeal.employeeResponse.closingText),
                )}
              </Typography>
            </>
          )}
        </div>
      ),
      isHidden: !isShowNoteToCustomer || !isUpdatingStatus,
    },
    {
      translationLabel: 'note',
      value: (
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            {' '}
            {parse(
              DOMPurify.sanitize(
                customDeal.employeeResponse?.openingText ?? '',
              ),
            )}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {' '}
            {parse(
              DOMPurify.sanitize(
                customDeal.employeeResponse?.editableText ?? '',
              ),
            )}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {' '}
            {parse(
              DOMPurify.sanitize(
                customDeal.employeeResponse?.closingText ?? '',
              ),
            )}
          </Typography>
        </div>
      ),
      isHidden:
        customDeal.status !== ECustomDealStatusType.Declined ||
        !customDeal.employeeResponse ||
        isUpdatingStatus,
    },
    {
      translationLabel: 'type',
      value: (
        <SelectButton
          value={customDeal.dealType}
          onChange={(e) => {
            if (!e.target.value) return
            onChangeDealType(e.target.value as EDealType)
          }}
          options={[
            {
              label: t('pawn'),
              value: EDealType.Pawn,
            },
            { label: t('purchase'), value: EDealType.Purchase },
          ]}
        />
      ),
    },
    {
      translationLabel: 'employee.label',
      value: (
        <CustomDealEmployeeAutocomplete
          options={
            employees?.map((employee) => ({
              value: employee._id,
              label: `${employee.firstname} ${employee.lastname}`,
            })) || []
          }
          onChangeField={onChangeField}
          fieldName="employeeId"
          initialValue={
            selectedEmployee &&
            `${selectedEmployee.firstname} ${selectedEmployee.lastname}`
          }
        />
      ),
      isHidden: !employees,
    },
    customDeal.dealType === EDealType.Pawn && {
      translationLabel: 'duration_in_days',
      value: (
        <SelectButton
          value={customDeal.durationInDays}
          options={[30, 60, 90].map((option) => ({
            name: option,
            value: option,
            disabled: option < minimumPawnDuration,
          }))}
          onChange={(e) => {
            if (!e.target.value) return
            onChangeField('durationInDays', e.target.value)
          }}
          optionLabel="name"
          optionValue="value"
          optionDisabled="disabled"
          disabled={customDeal?.dealType !== EDealType.Pawn}
        />
      ),
    },
    {
      translationLabel: 'transport_type',
      value: (
        <SelectButton
          value={customDeal.pickupTransportMode ?? ''}
          onChange={(e) => {
            onChangeField(
              'pickupTransportMode',
              e.target.value as ETransportMode,
            )
          }}
          options={validTransportTypes.map((label) => ({
            label: t(`custom_deal_form.transport_types.${label}`),
            value: label,
          }))}
        />
      ),
      isHidden: isCarPawn,
    },
    {
      translationLabel: 'status',
      value: (
        <>
          <div>
            <CustomDealStatus
              status={customDeal.status}
              onEventChange={onChangeEventStatus}
            />
          </div>
          {customDeal.status === ECustomDealStatusType.Reviewing &&
            customDeal.issuanceCertificate?.url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={customDeal.issuanceCertificate.url}
              >
                <u>{t('download_issuance_certificate')}</u>
              </a>
            )}
        </>
      ),
      isHidden: !customDeal.events,
    },
    {
      translationLabel: 'payout.label',
      value: (
        <InputText
          className="w-64"
          value={displayLocalAmount(totalPayoutAmount)}
          disabled={true}
        />
      ),
    },
    {
      translationLabel: 'payout_reviewed_by',
      value: (
        <CustomDealEmployeeAutocomplete
          options={
            employees?.map((employee) => ({
              value: employee._id,
              label: `${employee.firstname} ${employee.lastname}`,
            })) || []
          }
          onChangeField={onChangeField}
          fieldName="payoutApproverId"
          initialValue={
            selectedApprover &&
            `${selectedApprover.firstname} ${selectedApprover.lastname}`
          }
        />
      ),
      isHidden: !employees,
    },
  ].filter((item) => !item.isHidden || !item.value)

  return (
    <div className="">
      <Typography variant="h6" className="u-mb-sm">
        {t('custom_deal.singular')}
        {customDeal.customDealNumber && (
          <span> #{customDeal.customDealNumber}</span>
        )}
      </Typography>

      <div className="flex flex-col md:flex-row gap-4">
        <Panel header={t('custom_deal_overview')} className="mb-6">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              {customDeal.dealId && (
                <Link to={`/inApp/deals/${customDeal.dealId}`}>
                  <Button>
                    <EuroOutlinedIcon classes={{ root: styles.dealIcon }} />
                    {t('to_deal')}
                  </Button>
                </Link>
              )}
            </div>
          </Box>
          <div className="flex flex-col gap-2 w-max">
            {dealFormItems.map((item) => (
              <div className="flex flex-row gap-2 items-center mr-4">
                <React.Fragment key={item.translationLabel}>
                  <Typography variant="subtitle1" className="w-52">
                    {t(item.translationLabel)}
                  </Typography>
                  {item.value}
                </React.Fragment>
              </div>
            ))}
          </div>
        </Panel>

        <CustomDealCustomer
          contactData={customDeal.contactData}
          onChangeField={onChangeField}
          customerId={customDeal.customerId}
          customer={customer}
          errors={errors.contactData}
          status={customDeal.status}
        />

        <Panel
          header={t('deal_conversation')}
          className="mb-6 w-full"
          toggleable
        >
          {customDeal._id ? (
            <NoteContainer
              notes={(customDeal.notes as Note[]) ?? []}
              chainNotes={[]}
              onAddNote={onAddDealNote}
              onUpdateNote={onUpdateDealNote}
              conversationListHeight={451}
            />
          ) : (
            <CreateNote
              horizontalMode
              isCreateMode
              onAddNote={(note) => {
                onChangeField('notes', [note])
              }}
            />
          )}
        </Panel>
      </div>
    </div>
  )
}

type TData = { getCustomDealById: Query['getCustomDealById'] }
type TVariables = { languageCode: ELanguageCode } & QueryGetCustomDealByIdArgs

interface FormItem {
  translationLabel: string
  value: React.ReactNode
  isHidden?: boolean
}

const validTransportTypes = [
  ETransportMode.Shop,
  ETransportMode.StandardShipment,
]
